//Colors
$myChoiceInactiveOrange: #FCAD8E;
$myChoiceTabBackground: #E5E5E5;
$myChoiceBlack: #212121;
$myChoiceDarkBlue: #2A363D;
$myChoiceGray: #777777;
$myChoicePlaceholderGray: #A7A7A7;
$myChoiceSemiGray: #C8C8C8;
$myChoiceNavbarColor: #666666;
$myChoiceTextGrey: #959595;
$myChoiceQuoteGreen: #02B917;

$color-white-strong: #ffffff;
$color-orange-light: #F1642F;
$color-black-strong: #000000;
$color-black-medium: #444444;
$color-red-strong: #f50011;
$color-blue-light: #176DE3;
$color-gray-extra-light: #F2F2F2;
$color-gray-light: #4C4B4B;
$color-gray-medium: #C8C8C8;

$color-text-title: $color-gray-light;
$color-input-border: $color-gray-light;
$color-warning-primary: $color-red-strong;
$color-text-secondary: $color-black-medium;
$color-text-tertiary: $color-black-strong;

$color-background-header: $color-white-strong;
$color-background-content: $color-gray-extra-light;
$color-background-secondary: $color-gray-medium;


$border-input-default: 1px solid $color-gray-light;
