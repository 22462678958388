//Inputs
@mixin input {
  border-radius: 4px;
  height: 60px;
  border: 1px solid $myChoicePlaceholderGray;
  outline: none;
  color: $myChoiceBlack;
  font-size: 18px;
  padding-left: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 15px 0 30px 0;
  font-family: 'Latoregular', sans-serif;
  &:focus {
    border: $border-input-default;
  }
  &:hover {
    border: $border-input-default;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $myChoicePlaceholderGray;
    font-family: 'Latoregular', sans-serif;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $myChoicePlaceholderGray;
    font-family: 'Latoregular', sans-serif;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $myChoicePlaceholderGray;
    font-family: 'Latoregular', sans-serif;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $myChoicePlaceholderGray;
    font-family: 'Latoregular', sans-serif;
  }
}

@mixin select {
  background: url('../../resources/images/dropdown-arrow.svg') no-repeat right $color-white-strong;
  -webkit-appearance: none;
  background-position-x: 95%;
  color: black;
  font-family: 'Latoregular', sans-serif;
  &:required:invalid {
    color: $myChoicePlaceholderGray;
  }
  &:focus {
    border: $border-input-default;
  }
  &:hover {
    border: $border-input-default;
  }
  &:disabled {
    background-color: $color-background-content;
    color: $myChoicePlaceholderGray;
  }
}

@mixin buttonSelected {
  height: 60px;
  width: 48%;
  background-color: $color-orange-light;
  border: 1px solid $color-orange-light;
  color: $color-white-strong;
  border-radius: 4px;
  margin: 15px 0 30px 0;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  font-family: 'Montserratbold', sans-serif;
}

@mixin buttonDeSelected {
  height: 60px;
  width: 48%;
  background-color: $color-white-strong;
  border: 1px solid $color-text-secondary;
  color: $color-text-secondary;
  border-radius: 4px;
  margin: 15px 0 30px 0;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  font-family: 'Montserratregular', sans-serif;
}


// Breakpoints
@mixin bp-large {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin bp-medium {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin bp-small {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin bp-not-mobile {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin iPad-landscape {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    @content;
  }
}

@mixin iPad-portrait {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    @content;
  }
}
