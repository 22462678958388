.appNavbarWrapper {
  width: 100%;
  z-index: 40;
  height: 114px;
  background-color: $color-background-header;

  .navbarContentWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    @include bp-small {
      width: auto;
      padding: 0 10px;
    }
    @include iPad-landscape {
      width: auto;
      justify-content: center;
      padding: 0 10px;
    }
    @include iPad-portrait {
      width: auto;
      justify-content: center;
      padding: 0 10px;
    }

    .linksWrapper {
      position: relative;
      a {
        position: relative;
      }
      .the-big-back {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .userMenuWrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      background: $color-white-strong;
      border-radius: 3px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      justify-content: center;
      align-items: center;
      right: 0;
      top: 34px;
      width: 130px;
      .userMenuLink {
        font-size: 14px;
        color: $myChoiceDarkBlue;
        font-family: $font-primary-light;
        height: auto;
        padding: 15px 0;
        border-bottom: 1px solid #ededed;
        width: 100%;
        text-align: center;
        &:hover:after {
          content: none;
        }
        &.active:after {
          content: none;
        }
      }
      .active {
        font-family: 'Latoregular', sans-serif;
      }
      .logOutBtn {
        font-size: 14px;
        color: $color-orange-light;
        font-family: $font-primary-light;
        height: auto;
        padding: 15px 0;
        width: 100%;
        text-align: center;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
    /*
    a {
      font-size: 14px;
      color: $color-white-strong;
      height: 50px;
      padding-left: 40px;
      text-decoration: none;
      position: relative;
      font-family: 'Latoblack', sans-serif;
      &:hover:after {
        content: " ";
        position: absolute;
        bottom: -17px;
        width: auto;
        height: 0;
        border: 3px solid $color-orange-light;
        margin-left: auto;
        margin-right: auto;
        left: 40px;
        right: 0;
      }
      @include iPad-portrait {
        font-size: 12px;
        padding-left: 30px;
      }
      &:hover:after {
        left: 40px;
      }
    }*/
    .active:after {
      content: " ";
      position: absolute;
      width: 100%;
      bottom: -17px;
      height: 0;
      border-bottom: 3px solid $color-orange-light;
      margin-left: auto;
      margin-right: auto;
      right: 0;
    }

    .logInLink {
      border: 1px solid #fff;
      border-radius: 4px;
      margin-left: 30px;
    }

    .signUpLink {
      color: $color-orange-light;
      margin-left: 30px;
    }

    .logoLink {
      padding-left: 0;
      &:hover:after {
        content: none;
      }
      &.active:after {
        content: none;
      }
    }

    h3 {
      display: inline-block;
      padding-left: 40px;
      line-height: 16px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      font-family: 'Latoblack', sans-serif;
      img {
        margin-left: 10px;
        width: 10px;
        margin-bottom: 2px;
      }
    }
    .mobile-menu-toggle-button {
      background: transparent;
      border: none;
      width: 40px;
      height: 40px;
      position: relative;
      outline: none;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 5px;
        width: 50%;
        background: $color-orange-light;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(even) {
          left: 50%;
          border-radius: 0 9px 9px 0;
        }

        &:nth-child(odd) {
          left:0;
          border-radius: 9px 0 0 9px;
        }

        &:nth-child(1), &:nth-child(2) {
          top: 10px;
        }

        &:nth-child(3), &:nth-child(4) {
          top: 18px;
        }

        &:nth-child(5), &:nth-child(6) {
          top: 26px;
        }
      }
    }

    .open {
      span {
        &:nth-child(1), &:nth-child(6) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(2), &:nth-child(5) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        &:nth-child(1) {
          left: 1px;
          top: 11px;
        }
        &:nth-child(2) {
          left: calc(50% - 5px);
          top: 11px;
        }
        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }
        &:nth-child(5) {
          left: 1px;
          top: 24px;
        }
        &:nth-child(6) {
          left: calc(50% - 5px);
          top: 24px;
        }

      }
    }

    .the-big-logotype{
      width: 280px;

      padding: 25px 0 15px 60px;
      @media (max-width: 540px) {
        width: 220px;
        padding: 5px;
      }
      img{
        width:100%;
        height: auto;
      }
    }
    .the-big-back {
      padding: 15px 60px 15px 0;
      margin-left: 30px;
      @include bp-small {
        padding: 0;
      }
      a {
        color: #cccccc;
        font-family: 'Montserratbold', sans-serif;
        font-size: 140%;
        font-weight: 700;
        vertical-align: top;
        text-transform: uppercase;
      }
      a::before{
        content: "";
        display: inline-block;
        background-image: url('../../resources/images/the-big-header-back.png');
        background-repeat: no-repeat;
        width: 30px;
        height: 25px;
        margin-right: 10px;
        margin-top: 2px;
        vertical-align: top;
      }
    }
  }
  .mobile-menu-links {
    text-align: left;
    overflow:hidden;
    background-color: $color-white-strong;

    a {
      font-size: 20px;
      display: block;
      padding: 15px 0;
      color: $color-black-strong;
      text-decoration: none;
      border-bottom: 1px solid #d2d2d2;
      width: 60%;
      margin: 0 auto;
      &:last-child {
        border-bottom: none;
      }
    }

    .logOutBtn {
      font-size: 20px;
      color: $color-orange-light;
      font-family: $font-primary-light;
      height: auto;
      padding: 15px 0;
      width: 100%;
      text-align: center;
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
    }

    p {
      color: $color-white-strong;
    }

    .active {
      color: $color-orange-light;
    }

    .mobile-menu-links-wrapper{
      position: relative;
      padding-top: 48px;
      padding-bottom: 20px;
      height: 100vh;
      background-color: $myChoiceNavbarColor;
    }
    .mobile-menu-links-wrapper-active {
      margin-top: 0;
    }
    .mobile-menu-links-wrapper-passive {
      height:0;
      margin-top: 0;
    }

  }
  .mobile-menu-links-passive{
    height:0;
  }
  .mobile-menu-links-active{
    height: 100vh;
  }
  .animated-ease-out-in {
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  .animated-ease-out-in-slow {
    -webkit-transition: .5s ease-out;
    -moz-transition: .5s ease-out;
    -o-transition: .5s ease-out;
    transition: .5s ease-out;
  }
  .hiddenBlock {
    height: calc(100vh - 430px);
  }
}
