.modal-large {
  max-width: 424px;
  width: 424px;
  box-sizing: border-box;
  @include bp-small {
    max-width: 320px;
  }
}
.modal-small {
  max-width: 284px;
  width: 284px;
  box-sizing: border-box;
  h3 {
    padding: 0;
  }
  p {
    padding-bottom: 15px;
  }
}
.viewModalWrapper {
  padding: 10px;
  text-align: center;
  p{
    text-align: center;
    margin-bottom: 15px;
  }
  .warningMessage {
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
  }
  button{
    &:hover {
      opacity: 0.7;
    }
  }
  .viewModalButtonsWrapper {
    display: block;
    text-align: center;
    button{
      width: 195px;
      height: 60px;
      margin: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .alreadyMemberHintText {
    margin: 24px 0 0;
    span {
      font-family: 'Latobold', sans-serif;
      padding-left: 10px;
      color: $color-text-title;
      cursor: pointer;
    }
  }
  .loading {
    display: flex;
    flex-direction: row;
    padding-left: 44%;

    div {
      height: 7px;
      width: 7px;
      margin-right: 3%;
      background-color: $color-white-strong;
      border-radius: 50%;
      animation-name: blink;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: .1s;
      }

      &:nth-child(3) {
        animation-delay: .2s;
      }
    }

    @keyframes blink {
      0% {
        opacity: .2;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: .2;
      }
    }
  }
  .formInput {
    @include input;
    width: 195px;
    margin: 0  auto 30px auto;
    &:hover{
      border: $border-input-default;
    }
  }
  .postalInput {
    text-align: center;
    padding: 0 30px;
  }
  .actionModalButtonsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    button{
      width: 48%;
      margin: 0;
      height: 60px;
      &:hover {
        opacity: 0.7;
      }
    }
    .phoneNumber {
      @include buttonSelected;
      width: 48%;
      height: 60px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
