body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6, p, a, ol li {
  margin: 0;
  font-family: 'Latoregular', sans-serif;
}

h1, h2, h3, h4, h5, h6{
  color: $color-text-title;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  white-space: pre-line;
}

h5 {
  font-size: 16px;
}

p {
  font-size: 16px;
}

.formInputHidden{
  display: none;
}

input {
  filter: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: inset 0 0 0 9999px white;
}

button {
  cursor: pointer;
}

.icon-loader-primary {
  stroke: $color-background-header;
}
