.quotesWrapper {
  background-color: $color-white-strong;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 4px 4px;
  padding: 66px 30px 60px 30px;
  position: relative;
  box-sizing: border-box;
  padding-top: 30px;
  width: 1170px;
  max-width: 1170px;
  min-height: 430px;
  display: grid;
  grid-template-rows: 100px;

  @include bp-small {
    width: auto;
    box-shadow: none;
  }
  @include iPad-portrait {
    width: auto;
  }
  @include iPad-landscape {
    width: auto;
  }

  .noQuotesHistoryInfo {
    text-align: center;
  }
}

.splash-screen-wrapper {
  padding: 30px;
  font-family: $font-primary-regular;
  color: $color-text-tertiary;
  text-align: center;
  font-size: 1.25em;

  h2{
    margin-bottom: 1em;
    color: $color-text-tertiary;
  }

  .phoneNumber {
    color: inherit;
    font: inherit;
    margin: 0 0.3em 0 0.3em;
  }
  img{
    width: 50%;
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

.quoteDetailOpened {
  background-color: #f2f2f2;
}

.mobileQuotePreview {
  border: 1px solid #bdbdbd;
  padding: 12px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  h4 {
    color: #212121;
  }
  .quoteDate {
    font-family: $font-primary-light;
    padding-top: 8px;
  }
  .currentQuotesCarList {
    color: $color-text-title;
    font-size: 14px;
    text-align: center;
    padding-top: 12px;
    span {
      padding-right: 10px;
    }
  }
  .viewQuoteDetailArrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.eachQuote {
  padding: 30px;
  width: 540px;
  max-width: 540px;
  height: auto;
  margin-bottom: 30px;
  background-color: $color-white-strong;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 4px 4px;
  position: relative;
  box-sizing: border-box;

  @include bp-small {
    width: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 4px 4px;
    height: auto;
  }

  .companyLogo {
    width: 160px;
    height: 60px;
    @include bp-small {
      width: 90px;
    }
  }
}

a {
  text-decoration: none;
  color: $color-orange-light;
  font-family: $font-primary-light;
}

p {
  color: $color-text-secondary;
}

.quote {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1rem;
  justify-content: center;

  p {
    font-family: $font-primary-light;
  }

  .careMake {
    font-family: 'Latoregular', sans-serif;
    padding-bottom: 5px;
  }

  @include bp-not-mobile {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    height: 70px;
    align-items: center;
  }
  @include iPad-portrait {
    height: 100px;
  }
}

.quoteBtnContainer{
  display: flex;
  justify-content: center;
  .getNewQuoteLink {
    width: 100%;
  }
}

.quoteBtn {
  @include buttonSelected;
  width: 195px;
  height: 45px;
  margin: 60px 0 30px 0;
  @include bp-small {
    width: 100%;
  }
}

.accordionContent {
  overflow: hidden;
  margin: 0 -30px;
  height: 0;
}

.accordionContentOpened {
  height: auto;
}

.accordionWrapper {
  border-bottom:1px solid $color-background-content;
  padding: 0 30px;
}

.accordion:visited {
  border: none;
  text-align: left;
  margin: 0;
  background: $color-background-secondary;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  appearance: none;

}

.firstQuote {
  p {
    color: $myChoiceTextGrey;
  }
}

.paragraph {
  color: $myChoiceTextGrey;
}

.quotesContainer {
  @include bp-not-mobile {
    margin: 0 -30px;
    //border-top: 1px solid $color-background-content;
  }
}

.rightArrowQuotes {
  background: url('../../resources/images/right-arrow-quotes.svg') no-repeat;
  height: 18px;
  width: 18px;
  cursor: pointer;

  @include bp-small {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.upArrowQuotes {
  background: url('../../resources/images/up-arrow.svg') no-repeat;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.viewDetails {
  display: flex;
  justify-content: space-between;
}

.activeBackground {
  background-color: $color-background-content;
}

.wrap {
  padding: 0;
}

.quoteWrapper {
  width: 1170px;
  max-width: 1170px;
  padding: 30px 30px 30px 30px;
  background-color: $color-white-strong;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 4px 4px;
  position: relative;
  box-sizing: border-box;
  @include bp-small {
    width: auto;
    box-shadow: none;
  }
  @include iPad-portrait {
    width: auto;
  }
  @include iPad-landscape {
    width: auto;
  }
}

.quoteText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1095px;
  color: $color-text-secondary;
}

.quoteTextContainer {
  display: grid;
  grid-row-gap: 15px;
}

.title {
  padding-bottom: 30px;
  color:  $color-text-secondary;
}

.singleQuoteContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  @include bp-small {
    display: grid;
    grid-template-columns: 1fr;
  }
  .insurancetext {
    font-size: 16px;
  }
}

.quoteDescription {
  color: $color-orange-light;
  text-align: right;
}

.quoteType {
  grid-column: 1/3;
}

.quoteTitle {
  color: $color-orange-light;
  padding: 30px 0;
}

.insuranceContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2rem;
  .offersFourthRow {
    h3 {
      margin-bottom: 15px;
      font-family: 'Latobold', sans-serif;
      color: $color-text-secondary;
    }

    h5 {
      margin-bottom: 10px;
      font-family: 'Latobold', sans-serif;
      color: $color-text-secondary;

      span {
        font-family: $font-primary-light;
      }
    }

    p {
      font-size: 20px;
      font-family: $font-primary-light;
      margin-bottom: 30px;
      border-bottom: 1px solid #707070;
      padding-bottom: 30px;
    }
  }
  .brokerLogo {
    width: 100px;
    margin: 0 0 20px 0;
    @include bp-small {
      width: 90px;
    }
  }
  .phoneNumber {
    @include buttonSelected;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 210px;
    margin: 15px 0 30px 0;
  }
}

.bestOffer {
  height: 42px;
  width: 128px;
  background-color: $myChoiceQuoteGreen;
  border: 1px solid $myChoiceQuoteGreen;
  color: $color-white-strong;
  border-radius: 4px;
  font-size: 17px;
  outline: none;
  cursor: pointer;
  font-family: 'Latobold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newBestOffer {
  height: 25px;
  width: 102px;
  margin-left: 10px;
  background-color: $myChoiceQuoteGreen;
  border: 1px solid $myChoiceQuoteGreen;
  color: $color-white-strong;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'Latobold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp-small {
    margin-top: 15px;
    margin-bottom: 3px;
    margin-left: 0;
  }
}

.newBestOfferWrapper {
  display: flex;
}

.bestOfferWrapper {
  height: 44px;
}

.insuranceText {
  color: $color-text-secondary;
  height: 40px;
}

.insuranceMonthlyPrice {
  color: $myChoiceBlack;
  @include bp-small {
    font-size: 30px;
    span {
      font-size: 13px;
    }
  }
}
