ol.progtrckr {
    list-style-type: none;
    display: grid;
    padding: 0;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    margin-top: -25px;
}

ol.progtrckr a{
    position: relative;
    text-decoration: none;
    color: #212121;
}

@media only screen and (max-width: 767px) {
  ol.progtrckr a{
    display: flex;
    align-items: center;
  }
}

ol.progtrckr li {
    display: flex;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
}
ol.progtrckr li span {
    padding: 0;
}


.progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
}
@media (max-width: 650px) {
    .progtrckr em {
        display: inline;
    }
}

ol.progtrckr li.progtrckr-todo {
    color: silver;
    position: relative;
}

ol.progtrckr li.progtrckr-todo div{
    display: flex;
    flex-direction: column;
}

.sub-navigation-bottom-line {
    content: "";
    position: absolute;
    border-top: 2px solid $color-background-secondary;
    width: 100%;
    top: 103px;
    left: 50%;
    right: -50%;
    z-index: 2;
}

ol.progtrckr a.progtrckr-todo:not(:last-child):after {
    @extend .sub-navigation-bottom-line;
}
ol.progtrckr li.progtrckr-doing {
    color: #A7A7A7;
    position: relative;
}
ol.progtrckr li.progtrckr-doing div{
    display: flex;
    flex-direction: column;
}
ol.progtrckr a.progtrckr-doing:not(:last-child):after {
    @extend .sub-navigation-bottom-line;
}
ol.progtrckr li.progtrckr-done {
    color: #A7A7A7;
    position: relative;
}
ol.progtrckr li.progtrckr-done div{
    display: flex;
    flex-direction: column;
}
ol.progtrckr li.progtrckr-done:after {
    @extend .sub-navigation-bottom-line;
    border-top: 2px solid $color-gray-light;
}
ol.progtrckr li:before {
    position: relative;
    top: 93px;
    float: left;
    left: 43%;
    z-index: 3;
}
ol.progtrckr li.progtrckr-todo:before {
    content: "";
    color: silver;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #C8C8C8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
}
ol.progtrckr li.progtrckr-todo:hover:before {
    color: #F9733E;
}
ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    font-size: 12px;
    color: $color-gray-light;
    background-color: #ffffff;
    width: 20px;
    line-height: 20px;
    border-radius: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
ol.progtrckr li.progtrckr-doing:hover:before {
    color: $color-gray-light;
}

ol.progtrckr li.progtrckr-done:before {
    content: url("../../resources/images/tick.svg");
    background-color: $color-gray-light;
    width: 20px;
    line-height: 16px;
    border-radius: 50px;
    height: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
ol.progtrckr li.progtrckr-done:hover:before {
    color: #0FA0CE;
}

.multiStepContentWrapper {
    display: grid;
    grid-template-columns: 1.2fr 4fr 1fr;
    align-items: center;
    width: 100%;
}

.btns {
    width: 100px;
    position: relative;
    justify-self: flex-end;
}

.multiStepTitle {
   font-size: 14px;
   color: #2A363D;
   font-family: 'Latobold', sans-serif;
}

.prev-btn, .next-btn, .last-prev-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.finish-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 45px;
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $color-text-title;
    position: absolute;
    right: 0;
    bottom: -22px;
    color: #ffffff;
    font-size: 16px;
    font-family: 'Latobold', sans-serif;
      &:hover {
        opacity: 0.7;
      }
}

.clearFormBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 45px;
  border: $border-input-default;
  border-radius: 23px;
  outline: none;
  cursor: pointer;
  background-color: $color-background-header;
  position: absolute;
  left: -55px;
  bottom: -22px;
  color: $color-text-title;
  font-size: 16px;
  font-family: 'Latobold', sans-serif;
  &:hover {
    opacity: 0.7;
  }
}

.prev-btn {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: -22px;
    border: $border-input-default;
}

.last-prev-btn {
    background-color: #ffffff;
    position: absolute;
    left: 58px;
    //left: -45px; finish button return
    bottom: -22px;
    border: $border-input-default;
}

.next-btn {
    background-color: $color-text-title;
    position: absolute;
    right: 0;
    bottom: -22px;
}

.multiStepIcon {
    margin-bottom: 7px;
    height: 28px;
}


@media only screen and (max-width: 767px) {
    .multiStepContentWrapper {
        grid-template-columns: 3fr 1fr;
    }
    ol.progtrckr li.progtrckr-doing:before {
        display: none;
    }
    ol.progtrckr li.progtrckr-todo:before {
        display: none;
    }
    ol.progtrckr li.progtrckr-todo {
        display: none;
    }
    ol.progtrckr li.progtrckr-done {
        display: none;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        display: none;
    }
    .prev-btn, .next-btn, .last-prev-btn {
        width: 30px;
        height: 30px;
        bottom: -15px;
    }
    .finish-btn {
      width: 70px;
      height: 30px;
      font-size: 14px;
      bottom: -16px;
    }
    .clearFormBtn {
      height: 30px;
      bottom: -15px;
      left: -70px;
      width: 100px;
      font-size: 14px;
    }
    .next-btn img {
        width: 15px;
    }
    .prev-btn img {
        width: 15px;
    }

    .last-prev-btn {
        left: 32px;
        //left: -45px; finish button return
    }
    .btns {
        width: 65px;
        justify-self: center;
    }
    .multiStepIcon {
        margin-bottom: 0;
        height: auto;
        width: 30px;
    }
    .multiStepTitle {
        font-size: 20px;
        order: -1;
        text-align: left;
    }
    ol.progtrckr {
        margin-top: 0;
        grid-template-columns: 1fr;
        margin-bottom: 0;
        padding-left: 30px;
    }
    ol.progtrckr li {
        flex-direction: row;
    }
    ol.progtrckr li.progtrckr-doing div {
        align-items: flex-start;
        padding-left: 15px;
    }
    ol.progtrckr li span {
        padding: 0;
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    .postalCodeData {
        padding-left: 30px;
    }
    .multiStepContentWrapper {
        grid-template-columns: 1fr 3fr 1fr;
    }
    ol.progtrckr li:before {
        top: 108px;
    }
    ol.progtrckr li.progtrckr-done:after {
        left: 86px;
        right: -74px;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        left: 86px;
        right: -66px;
    }
    ol.progtrckr li.progtrckr-todo:not(:last-child):after {
        left: 86px;
        right: -66px;
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    .postalCodeData {
        padding-left: 30px;
    }
    ol.progtrckr li span {
        padding: 0;
    }
    .multiStepContentWrapper {
        grid-template-columns: 1fr 3fr 1fr;
    }
    ol.progtrckr li:before {
        top: 108px;
    }
    ol.progtrckr li.progtrckr-done:after {
        left: 69px;
        right: -54px;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        left: 69px;
        right: -55px;
    }
    ol.progtrckr li.progtrckr-todo:not(:last-child):after {
        left: 69px;
        right: -55px;
    }
}
