.formWrapper {
  background-color: $color-white-strong;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 4px 4px;
  width: 465px;
  max-width: 465px;
  padding: 66px 30px 60px 30px;
  position: relative;
  box-sizing: border-box;
  @include bp-small {
    width: auto;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
}

.signUpTitle {
  text-align: center;
  padding-bottom: 60px;
  font-family: 'Latobold', sans-serif;
}

.formLabelAndTooltipWrapper {
  display: flex;
  justify-content: space-between;

  img {
    cursor: pointer;
  }

  h4 {
    font-family: $font-primary-bold;
  }
}

.singUpInput {
  @include input;
  margin-bottom: 5px;
}

.errorInputBorder {
  border-color: $color-warning-primary;
}

.errorMsg {
  color: $color-warning-primary;
  font-family: 'Latoregular', sans-serif;
  font-size: 12px;
}

// Success messasge
.SuccessMsg {
  color: $color-text-title;
  font-family: 'Latoregular', sans-serif;
  font-size: 12px;
}

.inputWrapper {
  margin-bottom: 30px;
}

.selectedGenderBtn {
  @include buttonSelected;
  width: 30%;
  padding: 0;
  margin-bottom: 5px;
}

.deSelectedGenderBtn {
  @include buttonDeSelected;
  width: 30%;
  padding: 0;
  margin-bottom: 5px;
  color: $myChoicePlaceholderGray;
  border-color: $myChoicePlaceholderGray;
}

.genderButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.authSubmitBtn {
  @include buttonSelected;
  width: 100%;
  height: 45px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  &:hover {
    opacity: 0.7;
  }
}

.selectedBtn {
  @include buttonSelected;
}

.signUpLogin {
  font-weight: bold;
  color: $myChoiceBlack;
}

.passForgot {
  color: $myChoiceBlack;
  margin: -15px 0 15px 0;
  display: block;
}

.backBtnAndTitleWrapper {
  display: flex;
  align-items: center;
  //justify-content: start;
  padding-bottom: 60px;

  .signUpTitle {
    flex-grow: 1;
    padding-bottom: 0;
  }
  .backLink {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    background: $color-background-content;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
